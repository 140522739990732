





















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { DateTime, Duration } from 'luxon'
import SelectedService from '../models/SelectedService'
import { localTimeStringToUtcString } from '../utils/dateUtils'
import Booking from '../models/Booking'
import Portal from '../models/Portal'
import AvailableTime from '../models/AvailableTime'
import CustomerInfo from '../models/CustomerInfo'
import Reference from '../models/Reference'
import CreateBooking, { CreateBookingError } from '../utils/CreateBooking'

const DateFormat = 'yyyy-MM-dd'
const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss'

class DayInWeek {
  public index: number
  public date: DateTime
  public dummy: boolean

  public constructor(dict = null) {
    if (!dict) {
      dict = {}
    }
    this.index = dict.index || null
    this.date = dict.date || null
    this.dummy = dict.dummy || false
  }

  public get dayLabel() {
    return this.date ? '' + this.date.day : ''
  }

  public get dateLabel() {
    return this.date ? this.date.toFormat(DateFormat) : ''
  }
}

class Week {
  public week: number
  public days: Array<DayInWeek>
}

@Component({})
export default class CalendarMobile extends Vue {
  @Prop({ type: SelectedService, required: true })
  private selectedService: SelectedService

  @Prop({ type: Portal, required: false })
  private portal: Portal

  @Prop({ type: CustomerInfo, required: false })
  private customer: CustomerInfo

  @Prop({ type: String, required: true })
  private timezone: string

  @Prop({ type: Boolean, required: false })
  private isAdmin: boolean

  @Prop({ type: Reference, required: false })
  private reference: Reference

  @Prop({ type: Booking, required: false })
  private modifyExistingBooking: Booking

  private duration = 0
  private availableTimesInternal = {}
  private maxWeekDay = 0
  private today: DateTime = null
  private loading = true
  private availableTimesError = false
  private day: DayInWeek = null
  private errorDialog = {
    title: '',
    text: '',
    visible: false,
  }

  public created(): void {
    this.today = DateTime.now()
  }

  private get calendarId(): number {
    return this.portal ? this.portal.calendarId : null
  }

  private get portalId(): number {
    return this.portal ? this.portal.id : null
  }

  // =============================================================================
  // Load available times
  // These are the basis for various state, that we update each time they change
  // =============================================================================

  @Watch('url')
  public loadAvailableTimes(): void {
    // todo: consolidate with Calendar-NonMobile
    this.availableTimesError = false
    if (
      !this.selectedService.serviceId ||
      !this.selectedService.placeId ||
      !this.startDateTimeString ||
      !this.endDateTimeString ||
      !this.portalId
    ) {
      this.onAvailableTimesChange([])
      this.loading = false
      return
    }
    this.loading = true
    this.$axios
      .get(this.url)
      .then((response) => {
        this.duration = response.data.data.duration
        this.onAvailableTimesChange(response.data.data.available)
        this.loading = false
      })
      .catch((err) => {
        this.onAvailableTimesChange([])
        this.availableTimesError = true
        this.loading = false
        console.error('Error fetching available times: ' + err.response?.data?.error?.message, err)
      })
  }

  private onAvailableTimesChange(availableTimes: Array<AvailableTime>): void {
    let maxWeekDay = 0
    const available = {}
    for (let i = 0; i < availableTimes.length; i++) {
      const dt = DateTime.fromISO(availableTimes[i].start.replace(' ', 'T'), { zone: 'UTC' })
      if (dt.weekday > maxWeekDay) {
        maxWeekDay = dt.weekday
      }
      const time = dt.setZone(this.timezone).toFormat('HH:mm')
      const datePart = dt.setZone(this.timezone).toFormat(DateFormat)
      if (!available[datePart]) {
        available[datePart] = {}
      }
      available[datePart][time] = 1
    }
    this.availableTimesInternal = available
    this.maxWeekDay = maxWeekDay
  }

  // =============================================================================
  // Params for available times
  // Changes in these results in a new url that triggers loading new available times
  // =============================================================================

  private get url(): string {
    let params =
      '' +
      '?serviceId=' +
      this.selectedService.serviceId +
      '&placeId=' +
      this.selectedService.placeId +
      '&startTime=' +
      this.startDateTimeString +
      '&endTime=' +
      this.endDateTimeString
    if (this.selectedService.effectiveResourceIdAsString) {
      params += '&resourceId=' + this.selectedService.effectiveResourceIdAsString
    }
    for (let i = 0; i < this.selectedService.addonServiceIds.length; i++) {
      params += '&addonServiceIds[]=' + this.selectedService.addonServiceIds[i]
    }
    return '/v4/booking/portals/' + this.portalId + '/available-times' + params
  }

  private get startDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time =
      this.today.day > 1 ? this.today.minus(Duration.fromISO('P' + (this.today.day - 1) + 'D')) : this.today
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T00:00:00', { zone: 'UTC' })
  }

  private get endDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time = this.startDateTime.plus(Duration.fromISO('P1M')).minus(Duration.fromISO('P1D'))
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T23:59:59', { zone: 'UTC' })
  }

  private get startDateTimeString(): string {
    const time = this.startDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  private get endDateTimeString(): string {
    const time = this.endDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  // =============================================================================
  // Computed getters
  // =============================================================================

  private get days(): Array<{ labelLong: string; labelShort: string; day: number }> {
    const days = [
      { day: 1, labelLong: '' + this.$t('c:day:Mon'), labelShort: '' + this.$t('c:day-short:Mo') },
      { day: 2, labelLong: '' + this.$t('c:day:Tue'), labelShort: '' + this.$t('c:day-short:Tu') },
      { day: 3, labelLong: '' + this.$t('c:day:Wed'), labelShort: '' + this.$t('c:day-short:We') },
      { day: 4, labelLong: '' + this.$t('c:day:Thu'), labelShort: '' + this.$t('c:day-short:Th') },
      { day: 5, labelLong: '' + this.$t('c:day:Fri'), labelShort: '' + this.$t('c:day-short:Fr') },
      { day: 6, labelLong: '' + this.$t('c:day:Sat'), labelShort: '' + this.$t('c:day-short:Sa') },
      { day: 7, labelLong: '' + this.$t('c:day:Sun'), labelShort: '' + this.$t('c:day-short:Su') },
    ]
    const result = []
    for (let i = 0; i < this.maxWeekDay; i++) {
      result.push(days[i])
    }
    return result
  }

  // todo: weeks() and getDaysForWeek() are a bit messy, can probably be merged into a single more clean function. tidy it up, make more clear.

  private get weeks(): Array<Week> {
    const weeks = []
    const firstDateInMonthString = this.today.toFormat('yyyy-MM') + '-01T00:00:00'
    let date = DateTime.fromISO(firstDateInMonthString)
    let dates = []
    let week = date.weekNumber
    while (true) {
      dates.push(date)
      date = date.plus(Duration.fromISO('P1D'))
      if (date.weekNumber !== week) {
        weeks.push({
          week,
          days: this.getDaysForWeek(dates),
        })
        week = date.weekNumber
        dates = []
      }
      if (date.month > this.today.month || date.year > this.today.year) {
        weeks.push({
          week,
          days: this.getDaysForWeek(dates),
        })
        break
      }
    }
    return weeks
  }

  private getDaysForWeek(dates: Array<DateTime>): Array<DayInWeek> {
    const result = []
    if (!dates || dates.length === 0) {
      return []
    }
    const day = dates[0].weekday
    let nextDateIndex = 0
    for (let i = 1; i <= 7; i++) {
      if (i > this.maxWeekDay) {
        continue
      }
      if (i < day) {
        result.push(
          new DayInWeek({
            index: i,
            date: null,
            dummy: true,
          }),
        )
      } else {
        const nextDate = dates[nextDateIndex]
        nextDateIndex++
        if (nextDate) {
          result.push(
            new DayInWeek({
              index: i,
              date: nextDate,
              dummy: false,
            }),
          )
        } else {
          result.push(
            new DayInWeek({
              index: i,
              date: null,
              dummy: true,
            }),
          )
        }
      }
    }
    return result
  }

  private get hasAvailableTimes(): boolean {
    return Object.keys(this.availableTimesInternal).length > 0
  }

  private get times(): Array<{ time: string }> {
    if (!this.duration || !this.today) {
      return []
    }
    const date = this.day.date.toFormat(DateFormat)
    const times = []
    if (this.availableTimesInternal[date]) {
      for (const key in this.availableTimesInternal[date]) {
        times.push(key)
      }
    }
    times.sort()

    return times
  }

  // =============================================================================
  // Helpers
  // =============================================================================

  private getAvailableStatus(time: string, date: string): boolean {
    const times = this.availableTimesInternal[date]
    return times?.[time]
  }

  private getDayAvailableStatus(day: DayInWeek): boolean {
    if (!day.date) {
      return false
    }
    const times = this.availableTimesInternal[day.date.toFormat(DateFormat)]
    if (!times) {
      return false
    }
    return Object.keys(times).length > 0
  }

  // =============================================================================
  // Click action handlers
  // =============================================================================

  private get canGoToPreviousMonth(): boolean {
    const selectedMonth = this.today ? this.today.month : 0
    const selectedYear = this.today ? this.today.year : 0

    const currentDate = DateTime.now()
    const currentMonth = currentDate.month
    const currentYear = currentDate.year

    return selectedMonth > currentMonth || selectedYear > currentYear
  }

  private clickNextMonth(): void {
    this.today = this.today.plus(Duration.fromISO('P1M'))
  }

  private clickPreviousMonth(): void {
    if (!this.canGoToPreviousMonth) {
      return
    }
    this.today = this.today.minus(Duration.fromISO('P1M'))
  }

  private get monthName(): string {
    return this.today ? '' + this.today.monthLong : ''
  }

  private clickChooseDay(day: DayInWeek) {
    this.day = day
  }

  private clickChooseTime(time: string): void {
    this.loading = true
    const dateTimeStringUtc = localTimeStringToUtcString(
      this.day.date.toFormat(DateFormat) + 'T' + time + ':00',
      this.timezone,
    )
    if (this.modifyExistingBooking) {
      CreateBooking.update(
        this.$axios,
        this.calendarId,
        dateTimeStringUtc,
        this.selectedService,
        this.modifyExistingBooking,
        (err: CreateBookingError, booking: Booking) => {
          if (err) {
            this.errorDialog.title = '' + this.$t(err.title)
            this.errorDialog.text = '' + this.$t(err.text)
            this.errorDialog.visible = true
          } else {
            this.$emit('input', booking)
          }
          this.loading = false
        },
      )
    } else {
      CreateBooking.create(
        this.$axios,
        this.calendarId,
        this.portalId,
        dateTimeStringUtc,
        this.selectedService,
        this.customer,
        this.reference,
        this.isAdmin,
        (err: CreateBookingError, booking: Booking) => {
          if (err) {
            this.errorDialog.title = '' + this.$t(err.title)
            this.errorDialog.text = '' + this.$t(err.text)
            this.errorDialog.visible = true
          } else {
            this.$emit('input', booking)
          }
          this.loading = false
          this.loadAvailableTimes()
        },
      )
    }
  }

  private clickBack(): void {
    this.$emit('back')
  }
}
