















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Portal from '../models/Portal'
import CustomerInfo from '../models/CustomerInfo'
import Booking from '../models/Booking'
import Place from '../models/Place'
import Service from '../models/Service'
import ServiceInfo from '../components/ServiceInfo.vue'
import SelectedService from '@/models/SelectedService'
import PortalData from '@/utils/PortalData'

@Component({
  components: {
    ServiceInfo,
  },
})
export default class CompletedPage extends Vue {
  @Prop({ type: PortalData, required: true })
  private portalData: PortalData

  @Prop({ type: SelectedService, required: true })
  private selectedService: SelectedService

  @Prop({ type: Booking, required: false })
  private booking: Booking

  @Prop({ type: CustomerInfo, required: false })
  private customer: CustomerInfo

  @Prop({ type: Boolean, required: false })
  private showServiceInfo: boolean

  private isConfirmed = false

  private get portal(): Portal {
    return this.portalData.portal
  }

  private get place(): Place {
    return this.portalData.getPlace(this.selectedService)
  }

  private get service(): Service {
    return this.portalData.getService(this.selectedService)
  }

  private get addonServices(): Array<Service> {
    return this.portalData.getAddonServices(this.selectedService)
  }

}
