
















































import Vue from 'vue'
import Component from 'vue-class-component'
import CustomerInfo from '../models/CustomerInfo'
import { Prop } from 'vue-property-decorator'
import Booking from '@/models/Booking'

@Component({})
export default class CustomerInfoCollector extends Vue {
  @Prop({
    type: CustomerInfo,
    required: true,
  })
  private value: CustomerInfo

  @Prop({
    type: Array,
    required: false,
  })
  private required: Array<string>

  @Prop({
    type: Booking,
    required: false,
  })
  private modifyExistingBooking: Booking

  @Prop({ type: Boolean })
  private loading = false

  private error = ''
  private attemptSubmit = false

  private submit(): void {
    this.error = ''
    if (!this.validate()) {
      console.log('did not validate')
      return
    }
    this.$emit('complete')
  }

  private back(): void {
    this.reset()
    this.$emit('back')
  }

  private reset(): void {
    this.attemptSubmit = false
    this.validate(true)
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private validate(isReset = false): string | boolean {
    // This is to let second-step fields know they should start showing err if not valid,
    // because we don't want to do that before they click the button.
    this.attemptSubmit = !isReset
    // Trigger visual update of the inputs & check validness
    return this.form.validate()
  }

  private validateRequired(field: string, value: string): string | boolean {
    if (this.required.indexOf(field) !== -1) {
      value = (value || '').trim()
      if (this.attemptSubmit && !value) {
        return this.$t('Field is required') as string
      }
    }
    return true
  }
}
