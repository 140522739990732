





































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { formatDateTime } from '../utils/dateUtils'
import CustomerInfo from '../models/CustomerInfo'
import Portal from '../models/Portal'
import Booking from '../models/Booking'
import Service from '../models/Service'
import PortalData from '@/utils/PortalData'
import SelectedService from '@/models/SelectedService'

@Component({})
export default class ServiceInfo extends Vue {
  @Prop({ type: PortalData, required: false })
  private portalData: PortalData

  @Prop({ type: Number, required: true })
  private step: number

  @Prop({ type: SelectedService, required: false })
  private selectedService: SelectedService

  @Prop({ type: Booking, required: false })
  private booking: Booking

  @Prop({ type: CustomerInfo, required: false })
  private customer: CustomerInfo

  @Prop({ type: String, required: true })
  private timezone: string

  @Prop({ type: String, required: true })
  private timeformat: string

  private get portal(): Portal {
    if (!this.portalData || !this.selectedService) {
      return null
    }
    return this.portalData.portal
  }

  private get service(): Service {
    if (!this.portalData || !this.selectedService) {
      return null
    }
    return this.portalData.getService(this.selectedService)
  }

  private get addonServices(): Array<Service> {
    if (!this.portalData || !this.selectedService) {
      return null
    }
    return this.portalData.getAddonServices(this.selectedService)
  }

  private get count(): number {
    let count = 0
    if (this.service) {
      count += 1
    }
    if (this.addonServices) {
      count += this.addonServices.length
    }
    return count
  }

  private get bookingTimeFormatted(): string {
    if (this.booking) {
      return formatDateTime(this.booking.time, this.timezone, this.timeformat)
    } else {
      return ''
    }
  }

  private changeService(): void {
    this.$emit('change-service')
  }

  private changeTime(): void {
    this.$emit('change-time')
  }
}
