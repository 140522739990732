

















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import SelectedService from '../models/SelectedService'
import Service from '../models/Service'
import Place from '../models/Place'
import Slot from '../models/Slot'
import TyreHotel from '../models/TyreHotel'
import Resource from '../models/Resource'

// NB: Got some weird bugs when using v-model="value.placeId" and similar,
// so will try to handle receiving and emitting values manually.
@Component({})
export default class ServiceSelector extends Vue {
  // =========================================================================
  // Initial input values
  // Can in principle be updated externally at any time
  // =========================================================================

  @Prop({
    type: SelectedService,
    required: true,
  })
  private selectedService: SelectedService

  // =========================================================================
  // Internal state
  // =========================================================================

  private placeId: number = null
  private serviceId: number = null
  private addonServiceIds: Array<number> = []
  private resourceId: number = null

  private relevantAddonServices: Array<Service> = []
  private error = ''

  // =========================================================================
  // Customization properties
  // =========================================================================

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  private hideButton: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  private rememberPlace: boolean

  // =========================================================================
  // Properties related to portal
  // Typically does not change after PortalData is initially loaded
  // =========================================================================

  @Prop({
    type: Array,
    required: true,
  })
  private places: Array<Place>

  @Prop()
  private hidePlaceOnReBooking: boolean

  @Prop()
  private isExistingBooking: boolean

  @Prop({
    type: Array,
    required: true,
  })
  private primaryServices: Array<Service>

  @Prop({
    type: Array,
    required: true,
  })
  private addonServices: Array<Service>

  @Prop({
    type: Array,
    required: true,
  })
  private slots: Array<Slot>

  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  private resources: Array<Resource>

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  private showResource: boolean

  // =========================================================================
  // Tyre hotel info
  // Typically does not change after car-lookup on first input-licenseplate step
  // =========================================================================

  @Prop({
    type: TyreHotel,
    required: true,
  })
  private tyreHotel: TyreHotel

  // =========================================================================
  // Main
  // =========================================================================

  public mounted(): void {
    this.onChangeValue(this.selectedService, null)
  }

  @Watch('selectedService', { deep: true })
  private onChangeValue(newData, _oldData): void {
    this.placeId = newData.placeId || null
    this.serviceId = newData.serviceId || null
    this.resourceId = newData.resourceId || null
    this.addonServiceIds = newData.addonServiceIds || []
    if (this.rememberPlace && !this.placeId) {
      this.placeId = parseInt(localStorage.getItem('eon-cal-place')) || null
    }
    if (this.places.length === 1 && !this.placeId) {
      this.placeId = this.places[0].id
    }
    if (!this.resourceId) {
      this.resourceId = Resource.anyResourceId
    }
  }

  private emitValue(key, newValue, _oldValue): void {
    if (JSON.stringify(this.selectedService[key] || null) !== JSON.stringify(newValue || null)) {
      /*
      console.log(
        'EMIT:',
        key,
        'new',
        JSON.stringify(newValue),
        'old',
        JSON.stringify(_oldValue),
        'state',
        JSON.stringify(this[key]),
        'ext',
        JSON.stringify(this.selectedService[key]),
      )
      */
      const payload = {}
      payload[key] = newValue
      if (key === 'placeId') {
        payload['serviceId'] = null
        payload['resourceId'] = null
        payload['addonServiceIds'] = []
      } else if (key === 'serviceId') {
        payload['resourceId'] = null
        payload['addonServiceIds'] = []
      }
      this.$emit('change', payload)
    }
  }

  private get shouldShowPlace() {
    // If there are no places to choose from, we don't need to show (though this should in principle not happen)
    if (!this.places || this.places.length === 0) {
      return false
    }
    // If there's exactly one place, and it is selected, we don't need to show
    if (this.places.length === 1 && this.placeId === this.places[0].id) {
      return false
    }
    // If explicitly asked to hide place, we do that, but only if a place is already selected
    if (this.isExistingBooking && this.hidePlaceOnReBooking && this.placeId) {
      return false
    }
    // Else we show it
    return true
  }

  @Watch('placeId')
  private onChangePlaceId(newValue, oldValue): void {
    this.error = ''
    this.emitValue('placeId', newValue, oldValue)
    if (this.rememberPlace) {
      localStorage.setItem('eon-cal-place', '' + this.placeId)
    }
  }

  @Watch('serviceId')
  private onChangeServiceId(newValue, oldValue): void {
    this.error = ''
    this.emitValue('serviceId', newValue, oldValue)
    this.determineRelevantAddonServices()
  }

  @Watch('resourceId')
  private onChangeResourceId(newValue, oldValue): void {
    this.error = ''
    this.emitValue('resourceId', newValue, oldValue)
  }

  @Watch('addonServiceIds', { deep: true })
  private onChangeAddonServiceIds(newValue, oldValue): void {
    this.error = ''
    this.emitValue('addonServiceIds', newValue, oldValue)
  }

  private determineRelevantAddonServices(): void {
    const result = []
    const selectedAfter = []
    for (let i = 0; i < this.addonServices.length; i++) {
      const service = this.addonServices[i]
      service.isRequired = service.isRequiredForPrimaryService(this.serviceId)
      if (service.isVisibleForPrimaryService(this.serviceId)) {
        result.push(service)
        if (service.isRequired || this.addonServiceIds.indexOf(service.id) !== -1) {
          selectedAfter.push(service.id)
        }
      }
    }

    this.sortServices(result)
    this.relevantAddonServices = result
    this.addonServiceIds = selectedAfter
  }

  private get relevantPrimaryServices(): Array<Service> {
    // Get the service ids that are assigned to this place via slots
    const serviceIds = []
    const slots = this.slots
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].placeId === this.placeId) {
        if (serviceIds.indexOf(slots[i].serviceId) === -1) {
          serviceIds.push(slots[i].serviceId)
        }
      }
    }

    // Find the corresponding service objects
    const services = []
    for (let i = 0; i < serviceIds.length; i++) {
      for (let j = 0; j < this.primaryServices.length; j++) {
        if (this.primaryServices[j].id === serviceIds[i]) {
          const service = this.primaryServices[j]

          // Check if service is visible for tyre hotels
          switch (service.visibility) {
            case 'PublicIfTyreHotel':
              if (!this.tyreHotel.id) {
                continue
              }
              break
            case 'PublicIfNotTyreHotel':
              if (this.tyreHotel.id) {
                continue
              }
              break
          }

          // Check if service is applicable to the tyre hotel
          switch (service.mode) {
            // If service is a wheel change, and applies to only some services,
            // check if it's the correct service for our tyre hotel
            case 'TyreHotelSome':
              if (service.id !== this.tyreHotel.bookingServiceId) {
                continue
              }
              break
            // If service is a wheel change, and applies to any service,
            // then keep it only if we don't have a specific service for our tyre hotel
            case 'TyreHotelAny':
              if (this.tyreHotel.bookingServiceId) {
                continue
              }
              break
          }
          services.push(service)
          break
        }
      }
    }

    this.sortServices(services)

    // Return
    return services
  }

  private sortServices(services: Array<Service>) {
    services.sort((a: Service, b: Service) => {
      if (a.sortOrder === b.sortOrder) {
        if (a.name === b.name) {
          return 0
        }
        return a.name < b.name ? -1 : 1
      } else {
        return a.sortOrder < b.sortOrder ? -1 : 1
      }
    })
  }

  private get hasRelevantPrimaryServices(): boolean {
    return this.relevantPrimaryServices.length !== 0
  }

  private get hasRelevantAddonServices(): boolean {
    return this.relevantAddonServices.length !== 0
  }

  private getServiceDisplayName(service): string {
    let name = service.name
    if (service.isRequired) {
      name += ' - ' + this.$t('required')
    }
    return name
  }

  private get relevantResources(): Array<Resource> {
    // Get the resource ids that are assigned to this place + service via slots
    const resourceIds = []
    const slots = this.slots
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].placeId !== this.placeId) {
        continue
      }
      if (slots[i].serviceId !== this.serviceId) {
        continue
      }
      resourceIds.push(slots[i].resourceId)
    }

    // Find the corresponding service objects
    const resources = []
    resources.push(Resource.newAnyResource())
    for (let i = 0; i < this.resources.length; i++) {
      const resource = this.resources[i]
      if (resourceIds.indexOf(resource.id) !== -1) {
        resources.push(resource)
      }
    }

    return resources
  }

  private get hasRelevantResources(): boolean {
    return this.relevantResources.length > 0
  }

  private submit(): void {
    if (!this.serviceId) {
      this.error = '' + this.$t('c:booking:You must select a service first')
      return
    }
    this.$emit('submit')
  }
}
