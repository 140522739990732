
















































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Calendar from '@/components/Calendar.vue'
import CalendarMobile from '@/components/CalendarMobile.vue'
import LicenseplateCollector from '@/components/LicenseplateCollector.vue'
import ServiceSelector from '@/components/ServiceSelector.vue'
import CustomerInfoCollector from '@/components/CustomerInfoCollector.vue'
import SelectedService from '@/models/SelectedService'
import CustomerInfo from '@/models/CustomerInfo'
import ServiceInfo from '@/components/ServiceInfo.vue'
import CompletedPage from '@/components/CompletedPage.vue'
import Booking from '@/models/Booking'
import TyreHotel from '@/models/TyreHotel'
import { vxm } from '@/store'
import i18n from '@/i18n.ts'
import PortalData from '@/utils/PortalData'
import { AxiosError } from 'axios'

class StepsEnum {
  public OneLicenseplate = 1
  public TwoService = 2
  public ThreeCalendar = 3
  public FourInfo = 4
  public FiveComplete = 5
}

const Steps = new StepsEnum()

@Component({
  components: {
    LicenseplateCollector,
    ServiceSelector,
    CustomerInfoCollector,
    Calendar,
    CalendarMobile,
    ServiceInfo,
    CompletedPage,
  },
})
export default class BookingPortal extends Vue {
  private portalData: PortalData
  private isReady = false // prevent loading/reactivity before we've loaded PortalData and possible ExistingBooking

  // State
  private step = Steps.OneLicenseplate
  private loading = true
  private booking: Booking = null
  private selectedService = new SelectedService()
  private customer = new CustomerInfo()
  private tyreHotel = new TyreHotel()
  private modifyExistingBooking: Booking = null
  private requestToken = ''
  private selectedServiceKey = '' // for force-update on change SelectedService props
  private error = ''
  private isServiceInfoVisible = false

  private get portalId(): string {
    return this.$route.params.id
  }

  public created(): void {
    this.recallCustomer()

    // todo: move to vxm?
    this.portalData = new PortalData(this.$axios)
    this.portalData.loadPortalId(this.portalId, (err: AxiosError) => {
      if (err) {
        console.error('Error fetching portal:', err, err.response?.data)
        this.error = err.response?.data?.error?.message || 'Unknown error'
      } else {
        if (this.portalData.places.length === 1) {
          this.selectedService.placeId = this.portalData.places[0].id
        }
        document.title = this.portalData.portal.name
        vxm.locale.setLocale(this.portalData.locale)
        if (this.$route.params.ticket && this.$route.params.secret) {
          this.tryLoadBooking()
        } else if (this.$route.params.token) {
          this.tryLoadRequest()
        } else {
          this.isReady = true
        }
      }
      this.loading = false
    })
  }

  private tryLoadRequest() {
    const token = this.$route.params.token
    this.$axios
      .get('/v4/booking/request/' + token)
      .then((response) => {
        if (response.data.data?.found) {
          const data = response.data.data
          this.customer.name = data.contactName as string
          this.customer.email = data.contactEmail as string
          this.customer.mobilePhone = data.contactMobile as string
          this.customer.licenseplate = data.carLicenseplate as string
          this.requestToken = token
          if (data.placeId) {
            this.selectedService.placeId = data.placeId
          }
          if (data.serviceId) {
            this.selectedService.serviceId = data.serviceId
          }
          this.isReady = true
          if (this.customer.licenseplate && this.customer.mobilePhone) {
            if (this.selectedService.serviceId) {
              this.stepSet(3)
            } else {
              this.stepSet(2)
            }
          }
        } else {
          this.error = '' + this.$t('Invalid booking request link (may have timed out?)')
        }
      })
      .catch((err) => {
        console.error('Error loading booking request:', err)
        this.error = '' + this.$t('Error loading booking request')
      })
  }

  private tryLoadBooking() {
    const ticket = this.$route.params.ticket
    const secret = this.$route.params.secret
    const url = '/v4/booking/bookings/' + ticket + '/' + secret
    this.$axios
      .get(url)
      .then((response) => {
        const data = response.data.data
        this.customer.name = data.contactName as string
        this.customer.email = data.contactEmail as string
        this.customer.mobilePhone = data.contactMobile as string
        this.customer.licenseplate = data.carLicenseplate as string
        this.customer.comment = data.comment as string
        const booking = new Booking()
        booking.populate(data)
        booking.isExisting = true
        this.booking = booking
        this.modifyExistingBooking = this.booking.clone()
        for (let i = 0; i < data.work.length; i++) {
          const work = data.work[i]
          if (work.isPrimary) {
            this.selectedService.placeId = work.placeId as number
            this.selectedService.serviceId = work.service.id as number
            // Maybe not such a good idea anyway, support uses this to intentionally force them to change service
            // this.portalData.ensurePrimaryService(work.service)
          } else {
            this.selectedService.addonServiceIds.push(work.service.id as number)
          }
        }
        this.tryLoadTyreHotel(this.customer.licenseplate, this.customer.mobilePhone, () => {
          if (this.portalData.portal.hideServiceOnReBooking) {
            this.stepSet(Steps.ThreeCalendar)
          } else {
            this.stepSet(Steps.TwoService)
          }
          this.isReady = true
        })
      })
      .catch((err) => {
        console.error('Error loading booking:', err)
        this.error = '' + this.$t('Error loading booking')
      })
  }

  private tryLoadTyreHotel(licenseplate, mobilePhone, callback) {
    if (!licenseplate || !mobilePhone) {
      callback()
      return
    }
    const url =
      '/v4/booking/calendars/' +
      this.portalData.portal.calendarId +
      '/lookup?licenseplate=' +
      encodeURIComponent(licenseplate) +
      '&mobilePhone=' +
      encodeURIComponent(mobilePhone)
    this.$axios
      .get(url)
      .then((response) => {
        this.tyreHotel.id = response.data.data.tyreHotelId
        this.tyreHotel.bookingServiceId = response.data.data.tyreHotelBookingServiceId
        this.tyreHotel.productDescription = response.data.data.tyreHotelProductDescription
        callback()
      })
      .catch((err) => {
        console.error('error loading car/hotel:', err)
        callback()
      })
  }

  public setLanguage(lang: string): void {
    this.portalData.locale.language = lang
    vxm.locale.setLocale(this.portalData.locale)
  }

  public destroyed(): void {
    i18n.report()
    this.unReserveTime()
  }

  private recallCustomer(): void {
    const item = localStorage.getItem('eon-booking-customer')
    if (!item) {
      return
    }
    const data = JSON.parse(item)
    if (!data) {
      return
    }
    this.customer.licenseplate = data.licenseplate
    this.customer.mobilePhone = data.mobilePhone
    this.customer.name = data.name
    this.customer.email = data.email
  }

  private rememberCustomer(): void {
    if (!this.modifyExistingBooking) {
      localStorage.setItem('eon-booking-customer', JSON.stringify(this.customer))
    }
  }

  @Watch('booking')
  private onBooked(booking: Booking) {
    if (booking) {
      if (this.modifyExistingBooking && !booking.isExisting) {
        this.step = Steps.FiveComplete
        this.onFinished()
      } else {
        this.step = Steps.FourInfo
      }
    }
  }

  private setTyreHotel(tyreHotel: TyreHotel) {
    this.tyreHotel = tyreHotel
  }

  // Steps

  private stepColor(step) {
    if (step === this.step) {
      return 'blue'
    } else if (step > this.step) {
      return 'gray'
    } else {
      return 'green'
    }
  }

  private stepIsComplete(step) {
    return this.step > step
  }

  private stepClick(step) {
    if (step === Steps.OneLicenseplate && this.modifyExistingBooking) {
      // don't allow goto step 1 for existing because lookup plate will wipe customer (and maybe stuff)
      return
    }
    if (this.step === Steps.FiveComplete) {
      return
    }
    if (step === 1 || this.stepIsComplete(step)) {
      this.stepSet(step)
    }
  }

  private onFinished() {
    this.$router.push({
      name: 'Bookings/View/WithAction',
      params: {
        portal: this.portalId,
        ticket: this.booking.ticket,
        secret: this.booking.secret,
        action: this.modifyExistingBooking ? 'modify' : 'create',
      },
    })
  }

  private stepSet(step) {
    const callback = () => {
      this.loading = false
      this.step = parseInt(step)
      if (this.step === Steps.FiveComplete) {
        this.onFinished()
      }
    }

    if (step < Steps.FourInfo && this.booking) {
      // If we go back to select-time step (or before), we un-reserve the time
      this.loading = true
      this.unReserveTime(() => {
        const calendar = this.$refs.calendar as Calendar
        if (calendar) {
          calendar.loadAvailableTimes()
        }
        this.booking = null
        callback()
      })
    } else if (step === Steps.FiveComplete) {
      // If finished, then finalize booking
      this.loading = true
      this.$axios
        .post('/v4/booking/bookings/' + this.booking.ticket + '/' + this.booking.secret + '/finalize', {
          carLicenseplate: this.customer.licenseplate,
          contactMobile: this.customer.mobilePhone,
          contactName: this.customer.name,
          contactEmail: this.customer.email,
          comment: this.customer.comment,
          requestToken: this.requestToken,
        })
        .then((response) => {
          this.booking.status = response.data.data.status
          this.rememberCustomer()
          callback()
        })
        .catch((err) => {
          console.error('Error on confirm booking:', err)
          this.error = '' + this.$t('An error occurred, please try again later')
          this.loading = false
        })
    } else {
      // For all other steps, it should be sufficient to simply set the step
      callback()
    }
  }

  private unReserveTime(callback = null) {
    if (!this.booking || this.booking.status !== 'Reservation') {
      if (callback) {
        callback()
      }
      return
    }
    this.$axios
      .put('/v4/booking/bookings/' + this.booking.ticket + '/' + this.booking.secret + '/cancel')
      .then(() => {
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        console.error('Failed to cancel reservation (will ignore): ' + err + ' : ', err?.response?.data?.error)
        if (callback) {
          callback()
        }
      })
  }

  private get breakpoint() {
    return this.$vuetify.breakpoint.name
  }

  private get responsiveConfig() {
    // For large screens, we show service-info box in all steps after a service has been selected
    // On medium screens, we show it only during the service-selection (because too big for calendar step)
    // On small screens, we never show it (possibly inline it on selected later, but that could take up too much space too, so start without it)
    // On final completed-page, we show it in on medium to xl, and when not shown we inline it below the completed page text
    switch (this.breakpoint) {
      case 'lg':
      case 'xl':
        return {
          leftColumns: this.isServiceInfoVisible ? 8 : 12,
          rightColumns: 4,
        }
      case 'md':
        return {
          leftColumns: this.isServiceInfoVisible ? 7 : 12,
          rightColumns: 5,
        }
      default:
        return {
          leftColumns: 12,
          rightColumns: 12,
        }
    }
  }

  private get isCalendarMobile() {
    switch (this.$vuetify.breakpoint.name) {
      case 'md':
      case 'lg':
      case 'xl':
        return false
      default:
        return true
    }
  }

  private get localTimeZone(): string {
    return vxm.locale?.locale?.timezone || 'UTC'
  }

  private get localTimeFormat(): string {
    return vxm.locale?.locale?.timeFormat || 'sv'
  }

  private get siteCountryName(): string {
    return vxm.locale?.locale?.countryName || ''
  }

  private onServiceSelectorChange(evt) {
    for (const key in evt) {
      this.selectedService[key] = evt[key]
    }
    this.selectedServiceKey = JSON.stringify(this.selectedService)
    this.updateIsServiceInfoVisible()
  }

  @Watch('step')
  private onStepChange() {
    this.updateIsServiceInfoVisible()
  }

  @Watch('breakpoint')
  private onBreakpointChange() {
    this.updateIsServiceInfoVisible()
  }

  private updateIsServiceInfoVisible() {
    switch (this.$vuetify.breakpoint.name) {
      case 'lg':
      case 'xl':
        this.isServiceInfoVisible = this.selectedService.serviceId && this.step !== Steps.OneLicenseplate
        break
      case 'md':
        this.isServiceInfoVisible =
          this.selectedService.serviceId && (this.step === Steps.TwoService || this.step === Steps.FiveComplete)
        break
      default:
        this.isServiceInfoVisible = this.selectedService.serviceId && this.step !== Steps.OneLicenseplate
        break
    }
  }
}
